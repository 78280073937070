import React from 'react'

import erroranybank from 'assets/images/anybank-register-error.png'

import * as s from '../../styles'

const AnybankItauError: React.FC<{ errorOnProofOfLife: boolean }> = ({
  errorOnProofOfLife = false,
}) => {
  if (errorOnProofOfLife) {
    return (
      <s.AnybankItauErrorWrapper>
        <s.Text margin="32px 0 0 0" type="heading4" bold color="graytheme6">
          Erro na validação de informações
        </s.Text>
        <s.Text type="headline" color="graytheme6">
          Não foi possível validar todas as informações fornecidas. Por favor,
          verifique os dados e documentos enviados e tente novamente.
        </s.Text>
        <s.Text type="headline" color="graytheme6">
          Se o erro persistir, entre em contato com o suporte.
        </s.Text>
        <s.ImageErrorWrapper>
          <img src={erroranybank} alt="Imagem de Erro Pix AnyBank" />
        </s.ImageErrorWrapper>
      </s.AnybankItauErrorWrapper>
    )
  }
  return (
    <s.AnybankItauErrorWrapper>
      <s.Text margin="32px 0 0 0" type="heading4" bold color="graytheme6">
        Erro no cadastro da conta
      </s.Text>
      <s.Text type="headline" color="graytheme6">
        Um erro aconteceu, tente novamente mais tarde. Caso o problema persista,
        entre em contato com o suporte.
      </s.Text>
      <s.ImageErrorWrapper>
        <img src={erroranybank} alt="Imagem de Erro Pix AnyBank" />
      </s.ImageErrorWrapper>
    </s.AnybankItauErrorWrapper>
  )
}

export default AnybankItauError
