import React from 'react'
import { showToast } from 'components/Toast'

import { useNonInitialEffect } from 'hooks/utils'

import * as s from '../../styles'
import { baseURLPixAnybankSendDocuments, postFormData } from 'services/api'

const INITIAL_STATE_ERRORS = {
  contractDocFile: false,
}

interface DocumentVerificationProps {
  companyDocumentNumber: string
  nextStep: () => void
  previousStep: () => void
  callDocumentSubmissionState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ]
  isLoadingState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  registrationIdState?: [string, React.Dispatch<React.SetStateAction<string>>]
}

const AnyBankItauDocumentsVerificationModal: React.FC<
  DocumentVerificationProps
> = ({
  companyDocumentNumber,
  nextStep,
  previousStep,
  callDocumentSubmissionState,
  isLoadingState,
  registrationIdState,
}) => {
  const [contractDocFile, setContractDocFile] = React.useState<File>()
  const [callDocumentSubmission, setCallDocumentSubmission] =
    callDocumentSubmissionState
  const [isLoading, setIsLoading] = isLoadingState
  const [registrationId, setRegistrationId] = registrationIdState
  const [formError, setFormError] = React.useState(INITIAL_STATE_ERRORS)

  const validateDocuments = () => {
    if (contractDocFile) {
      submitDocuments()
    } else {
      setFormError({
        contractDocFile: !contractDocFile,
      })
    }
  }

  React.useEffect(() => {
    if (callDocumentSubmission) {
      validateDocuments()
      setCallDocumentSubmission(false)
    }
  }, [callDocumentSubmission])

  useNonInitialEffect(() => {
    if (contractDocFile) {
      setFormError({ ...formError, contractDocFile: !contractDocFile })
    }
  }, [contractDocFile])

  async function submitDocuments() {
    setIsLoading(true)

    const documents = new FormData()
    documents.append(
      'legal_person_document',
      companyDocumentNumber.replace(/[^0-9]/g, '')
    )
    const contractRenamed = new File([contractDocFile], 'legal_person.pdf', {
      type: contractDocFile.type,
      lastModified: contractDocFile.lastModified,
    })
    documents.append('legal_person_file', contractRenamed)

    await postFormData(`${baseURLPixAnybankSendDocuments}`, {
      legal_person_document: documents.get('legal_person_document'),
      legal_person_file: documents.get('legal_person_file'),
    })
      .then((res) => {
        setIsLoading(false)
        setRegistrationId(res.data.registration_id)
        nextStep()
      })
      .catch((e) => {
        if (window['env']['name'] !== 'production') {
          setIsLoading(false)
          nextStep()
        }
        setIsLoading(false)
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response)?.detail || 'Algo deu errado',
        })
      })
  }

  return (
    <>
      <s.Sections>
        <s.AnyBankItauDocumentsValidationWrapper>
          <s.Section>
            <s.Text margin="0 0 16px 0" bold type="heading4" color="graytheme6">
              Upload de arquivo
            </s.Text>
            <s.Text margin="0 0 16px 0" type="paragraph" color="graytheme6">
              É necessário fazer upload do contrato social da seguinte empresa
              contratante do serviço:
            </s.Text>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                CNPJ da empresa
              </s.Text>
              <s.InputText
                testId="CNPJ-test"
                width={260}
                maxLength={14}
                disabled
                value={companyDocumentNumber}
              />
            </s.SectionLine>
            <s.Text margin="32px 0 16px 0" color="graytheme6" type="heading4">
              Contrato social da empresa
            </s.Text>
            <s.UploadDocumentButtonArea
              key={`${contractDocFile}-upload-contract`}
              value={contractDocFile}
              handleChange={(e: File) => {
                setContractDocFile(e)
              }}
              type="doc"
              alignFileRules="row"
              data-testid="upload-contract"
              allowedFormats={['pdf']}
              maxFileSize={5000000} // 5MB
              required={formError.contractDocFile}
              hasFileAlready={!!contractDocFile}
              clear={() => setContractDocFile(null)}
            />
          </s.Section>
        </s.AnyBankItauDocumentsValidationWrapper>
      </s.Sections>
    </>
  )
}

export default AnyBankItauDocumentsVerificationModal
