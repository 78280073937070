import React from 'react'

import { v4 as uuid } from 'uuid'

import { showToast } from 'components/Toast'
import * as s from '../styles'

import tbanksPrivacy from 'assets/files/tbanks_privacy.pdf'
import CompanyForm from './CompanyForm'
import RepresentantForm from './RepresentantForm'
import TokenForm from './TokenForm'
import PixSimplesModalContainer from './PixSimplesModalContainer'
import WelcomeStep from './WelcomeStep'

import AnyBankTbanksRepresentantForm from './AnyBankTbanksRepresentantForm'
import AnyBankItauCompanyForm from './AnybankItau/AnyBankItauCompanyForm'
import AnyBankItauDestinationAccountForm from './AnybankItau/AnyBankItauDestinationAccountForm'
import AnybankItauWelcomeStep from './AnybankItau/AnyBankItauWelcomeStep'
import AnyBankItauSuccessModal from './AnybankItau/AnyBankItauSuccessModal'
import TbanksCompanyForm from './AnyBankTbanksCompanyForm'
import AnyBankItauLegalRepresentantativeForm from './AnybankItau/AnyBankItauLegalRepresentantiveForm'
import Loading from './Loading'
import AnyBankIdentityValidationModal from './AnybankItau/AnyBankItauIdentityValidationModal'
import AnyBankItauDocumentsVerificationModal from './AnybankItau/AnyBankItauDocumentsVerificationModal'
import AnyBankItauEndOnboardModal from './AnybankItau/AnyBankItauEndOnboardModal'
import AnybankItauError from './AnybankItau/AnybankItauError'
import AnyBankItauIdWallSdkRender from './AnybankItau/AnyBankItauIdWallSdkRender'
import AnybankItauTermsAndConditions from './AnybankItau/AnybankItauTermsAndConditions'
import useSdkIdWall from 'hooks/useSdkIdWall'

export type ItauAnyBankOnboardStatusType =
  | 'finished'
  | 'rejected'
  | 'in-progress'
  | 'pending-onboarding'
  | ''

interface IProps {
  isOpen: string
  handleClose: () => any
  isFrom: string
  fromStep?: string
  fromStatus?: ItauAnyBankOnboardStatusType
  companyDocument?: string
  pixUuid?: string
}

export interface FormProps {
  destinationAccount: {
    account: string
    bank: string
    branch: string
  }
  company: {
    name: string
    phone_number: string
    created_at: string
    email: string
    document_number: string
  }
  representative: {
    document_number: string
    name: string
    email: string
    phone_number: string
  }
  store_cnpj_cpf: string
}

export interface FormPropsItau {
  destination_account: {
    document_number: string
    pix_dict_key: string
    pix_dict_key_type: string
  }
  company: {
    document_number: string
    company_name: string
    fantasy_name: string
    income_invoicing: string
    phone_ddd: string
    phone_number: string
    email: string
    address: {
      street: string
      number: string
      complementary: string
      neighborhood: string
      city: string
      state: string
      zip_code: string
    }
  }
  legal_representative: {
    document_number: string
    name: string
    mother_name: string
    birth_date: string
    phone_ddd: string
    phone_number: string
    email: string
    address: {
      street: string
      number: string
      complementary: string
      neighborhood: string
      city: string
      state: string
      zip_code: string
    }
  }
}

export const initialSteps = {
  itauWelcomeStep: false,
  welcomeStep: false,
  itauDestinationAccount: false,
  tbanksCompanyForm: false,
  companyForm: false,
  itauDocumentsVerification: false,
  itauLoading: false,
  tbanksRepresentantForm: false,
  representantForm: false,
  itauCompanyForm: false,
  tokenForm: false,
  itauLegalRepresentativeForm: false,
  itauIdentityValidation: false,
  itauTermsAndConditions: false,
  itauIdWallSdkRender: false,
  itauEndOnboard: false,
  itauSuccess: false,
  error: false,
  errorOnProofOfLife: false,
}

export type InitialSteps = typeof initialSteps

const WalletSettingsFormPixSimples: React.FC<IProps> = ({
  isOpen,
  handleClose,
  isFrom,
  fromStep = 'welcomeStep',
  fromStatus = '',
  companyDocument = '',
  pixUuid = '',
}) => {
  const [step, setStep] = React.useState<InitialSteps>({
    ...initialSteps,
    [fromStep]: true,
  })
  const [isChecked, setIsChecked] = React.useState<boolean>(false)
  const [form, setForm] = React.useState<FormProps>({} as FormProps)
  const [formItau, setFormItau] = React.useState<FormPropsItau>(
    {} as FormPropsItau
  )
  const [isLoadingAnyBank, setIsLoadingAnyBank] = React.useState(false)
  const [registrationId, setRegistrationId] = React.useState<string>(pixUuid)
  const [callCompanyValidation, setCallCompanyValidation] =
    React.useState(false)
  const [callDocumentSubmission, setCallDocumentSubmission] =
    React.useState(false)
  const [callRepresentativeValidation, setCallRepresentativeValidation] =
    React.useState(false)
  const [callSignContract, setCallSignContract] = React.useState(false)
  const [enableGoToDocumentsVerification, setEnableGoToDocumentsVerification] =
    React.useState(false)
  const [enableSignContract, setEnableSignContract] = React.useState(false)
  const itauAnyBankOnboardStatus: ItauAnyBankOnboardStatusType = fromStatus

  useSdkIdWall()

  const handleCloseWithTracing = () => {
    if (window.hj) {
      window.hj('event', `modal-anybank-${isFrom}-step-${step}-close`)
    }
    if (window.gtag) {
      window.gtag('event', `modal_anybank_${isFrom}_create`, {
        modal_action: `modal_anybank_creation_closed_from_step_${step}`,
        modal_name: `create_pix_anybank_${isFrom}`,
        psp_name: isFrom,
        step: step,
        action: `close-from-step-${step}`,
      })
    }
    handleClose()
  }

  const nextStep = () => {
    if (
      (isFrom === 'tbanks' || isFrom === 'itau_anybank_v2') &&
      !isChecked &&
      step.welcomeStep
    ) {
      showToast({
        type: 'error',
        message:
          'Para continuar é necessário concordar com a a política de privacidade',
      })
    }

    if (step.itauWelcomeStep) {
      setStep({ ...initialSteps, itauDestinationAccount: true })

      if (window.gtag) {
        window.gtag('event', `modal_anybank_${isFrom}_create`, {
          modal_action: 'modal_anybank_creation_next_step',
          modal_name: `create_pix_anybank_${isFrom}`,
          pspName: isFrom,
          step: 'itauDestinationAccount',
        })
      }
    }
    if (step.itauDestinationAccount) {
      setStep({ ...initialSteps, itauDocumentsVerification: true })

      if (window.gtag) {
        window.gtag('event', `modal_anybank_${isFrom}_create`, {
          modal_action: 'modal_anybank_creation_next_step',
          modal_name: `create_pix_anybank_${isFrom}`,
          pspName: isFrom,
          step: 'itauDocumentsVerification',
        })
      }
    }
    if (step.itauDocumentsVerification) {
      setStep({ ...initialSteps, itauCompanyForm: true })

      if (window.gtag) {
        window.gtag('event', `modal_anybank_${isFrom}_create`, {
          modal_action: 'modal_anybank_creation_next_step',
          modal_name: `create_pix_anybank_${isFrom}`,
          pspName: isFrom,
          step: 'itauCompanyForm',
        })
      }
    }
    if (step.itauCompanyForm) {
      setStep({ ...initialSteps, itauLegalRepresentativeForm: true })

      if (window.gtag) {
        window.gtag('event', `modal_anybank_${isFrom}_create`, {
          modal_action: 'modal_anybank_creation_next_step',
          modal_name: `create_pix_anybank_${isFrom}`,
          pspName: isFrom,
          step: 'itauLegalRepresentativeForm',
        })
      }
    }
    if (step.itauLegalRepresentativeForm) {
      setStep({ ...initialSteps, itauIdentityValidation: true })

      if (window.gtag) {
        window.gtag('event', `modal_anybank_${isFrom}_create`, {
          modal_action: 'modal_anybank_creation_next_step',
          modal_name: `create_pix_anybank_${isFrom}`,
          pspName: isFrom,
          step: 'itauIdentityValidation',
        })
      }
    }
    if (step.itauIdentityValidation) {
      setStep({ ...initialSteps, itauIdWallSdkRender: true })

      if (window.gtag) {
        window.gtag('event', `modal_anybank_${isFrom}_create`, {
          modal_action: 'modal_anybank_creation_next_step',
          modal_name: `create_pix_anybank_${isFrom}`,
          pspName: isFrom,
          step: 'itauIdWallSdkRender',
        })
      }
    }

    if (step.welcomeStep) {
      if (isFrom === 'tbanks') {
        setStep({ ...initialSteps, tbanksCompanyForm: true })
      } else {
        setStep({ ...initialSteps, companyForm: true })
      }
    }

    if (step.tbanksCompanyForm) {
      setStep({ ...initialSteps, tbanksRepresentantForm: true })
    }

    if (step.tbanksRepresentantForm || step.representantForm) {
      setStep({ ...initialSteps, tokenForm: true })
    }

    if (step.companyForm) {
      setStep({ ...initialSteps, representantForm: true })
    }
  }

  const previousStep = () => {
    if (step.welcomeStep || step.itauWelcomeStep) {
      handleClose()
    }
    if (step.itauDestinationAccount) {
      setStep({ ...initialSteps, itauWelcomeStep: true })
    }
    if (step.itauDocumentsVerification) {
      setStep({ ...initialSteps, itauDestinationAccount: true })
    }
    if (step.itauCompanyForm) {
      setStep({ ...initialSteps, itauDocumentsVerification: true })
    }
    if (step.itauLegalRepresentativeForm) {
      setStep({ ...initialSteps, itauCompanyForm: true })
    }

    if (step.tbanksCompanyForm) {
      setStep({ ...initialSteps, welcomeStep: true })
    }

    if (step.tbanksRepresentantForm) {
      setStep({ ...initialSteps, tbanksCompanyForm: true })
    }

    if (step.representantForm) {
      setStep({ ...initialSteps, companyForm: true })
    }

    if (step.companyForm) {
      setStep({ ...initialSteps, welcomeStep: true })
    }

    if (step.tokenForm) {
      if (isFrom === 'tbanks') {
        setStep({ ...initialSteps, tbanksRepresentantForm: true })
      } else {
        setStep({ ...initialSteps, representantForm: true })
      }
    }
  }

  const WelcomeStepItau = () => {
    return (
      <>
        <AnybankItauWelcomeStep isFrom={isFrom} />
        <s.Footer>
          <s.WelcomeButtonItauAnybank
            onClick={() => {
              nextStep()
            }}
            width="340px"
            height="40px"
          >
            <s.Text color="white" type="paragraph">
              Começar Cadastro
            </s.Text>
            <s.Icon name="arrowright" fill="whiteshipay" />
          </s.WelcomeButtonItauAnybank>
        </s.Footer>
      </>
    )
  }

  const welcomeStep = () => {
    return (
      <>
        <WelcomeStep isFrom={isFrom} />
        <s.Footer>
          <s.CheckboxWrapper>
            {isFrom === 'tbanks' ? (
              <>
                <s.Checkbox
                  id={uuid()}
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(!isChecked)
                  }}
                />
                <s.Text>
                  Declaro estar ciente das
                  <s.ButtonLink href={tbanksPrivacy} target="_blank">
                    Políticas de privacidade
                  </s.ButtonLink>{' '}
                  do TBankS
                </s.Text>
              </>
            ) : null}
          </s.CheckboxWrapper>
          <s.RightButtonAnybank
            onClick={() => {
              nextStep()
            }}
            width="200px"
            height="40px"
          >
            Começar Cadastro
            <s.Icon name="arrowright" fill="whiteshipay" />
          </s.RightButtonAnybank>
        </s.Footer>
      </>
    )
  }

  React.useEffect(() => {
    if (window.gtag) {
      window.gtag('event', `modal_anybank_${isFrom}_create`, {
        modal_action: 'modal_anybank_creation_opened',
        modal_name: `create_pix_anybank_${isFrom}`,
        pspName: isFrom,
        step: step,
      })
    }
  }, [isFrom])

  const renderModal = () => {
    if (isLoadingAnyBank) {
      return <Loading />
    }
    if (step.itauWelcomeStep || step.welcomeStep) {
      if (isFrom === 'itau_anybank_v2') {
        return WelcomeStepItau()
      } else {
        return welcomeStep()
      }
    }

    if (step.itauDestinationAccount) {
      return (
        <AnyBankItauDestinationAccountForm
          pixFormState={[formItau, setFormItau]}
          nextStep={nextStep}
          previousStep={previousStep}
          isLoadingState={[isLoadingAnyBank, setIsLoadingAnyBank]}
          callRepresentantiveValidationState={[
            callRepresentativeValidation,
            setCallRepresentativeValidation,
          ]}
          setAllowedToGoToNextStep={setEnableGoToDocumentsVerification}
        />
      )
    }
    if (step.tbanksCompanyForm) {
      return (
        <TbanksCompanyForm
          nextStep={nextStep}
          isFrom={isFrom}
          previousStep={previousStep}
          pixFormState={[form, setForm]}
        />
      )
    }
    if (step.companyForm) {
      return (
        <CompanyForm
          nextStep={nextStep}
          isFrom={isFrom}
          previousStep={previousStep}
          pixFormState={[form, setForm]}
        />
      )
    }

    if (step.itauDocumentsVerification) {
      return (
        <AnyBankItauDocumentsVerificationModal
          companyDocumentNumber={formItau.company?.document_number}
          nextStep={nextStep}
          previousStep={previousStep}
          callDocumentSubmissionState={[
            callDocumentSubmission,
            setCallDocumentSubmission,
          ]}
          isLoadingState={[isLoadingAnyBank, setIsLoadingAnyBank]}
          registrationIdState={[registrationId, setRegistrationId]}
        />
      )
    }

    if (isFrom === 'tbanks') {
      return (
        <AnyBankTbanksRepresentantForm
          nextStep={nextStep}
          isFrom={isFrom}
          previousStep={previousStep}
          pixFormState={[form, setForm]}
          closeModal={handleCloseWithTracing}
        />
      )
    }

    if (step.representantForm) {
      return (
        <RepresentantForm
          nextStep={nextStep}
          isFrom={isFrom}
          previousStep={previousStep}
          pixFormState={[form, setForm]}
        />
      )
    }

    if (step.itauCompanyForm) {
      return (
        <AnyBankItauCompanyForm
          companyDocumentNumber={
            formItau.company?.document_number || companyDocument
          }
          nextStep={nextStep}
          isFrom={isFrom}
          callCompanyValidationState={[
            callCompanyValidation,
            setCallCompanyValidation,
          ]}
          previousStep={previousStep}
          pixFormState={[formItau, setFormItau]}
        />
      )
    }

    if (step.tokenForm) {
      return (
        <TokenForm
          isFrom={isFrom}
          previousStep={previousStep}
          handleClose={handleCloseWithTracing}
          pixFormState={form}
        />
      )
    }

    if (step.itauLegalRepresentativeForm) {
      return (
        <AnyBankItauLegalRepresentantativeForm
          legalRepresentativeDocumentNumber={
            formItau.legal_representative?.document_number || ''
          }
          isFrom={isFrom}
          callRepresentantiveValidationState={[
            callRepresentativeValidation,
            setCallRepresentativeValidation,
          ]}
          nextStep={nextStep}
          previousStep={previousStep}
          registrationId={registrationId}
          pixFormState={[formItau, setFormItau]}
          isLoadingState={[isLoadingAnyBank, setIsLoadingAnyBank]}
        />
      )
    }

    if (step.itauEndOnboard) {
      return (
        <AnyBankItauEndOnboardModal
          status={itauAnyBankOnboardStatus}
          handleClose={handleClose}
        />
      )
    }

    if (step.itauSuccess) {
      return <AnyBankItauSuccessModal handleClose={handleCloseWithTracing} />
    }

    if (step.error || step.errorOnProofOfLife) {
      return <AnybankItauError errorOnProofOfLife={step.errorOnProofOfLife} />
    }
    if (step.itauIdentityValidation) {
      return (
        <AnyBankIdentityValidationModal
          setStep={setStep}
          registrationId={registrationId}
          handleClose={handleClose}
        />
      )
    }
    if (step.itauIdWallSdkRender) {
      return (
        <AnyBankItauIdWallSdkRender
          setStep={setStep}
          registrationId={registrationId}
          setIsLoadingAnyBank={setIsLoadingAnyBank}
        />
      )
    }

    if (step.itauTermsAndConditions) {
      return (
        <AnybankItauTermsAndConditions
          callSignContractState={[callSignContract, setCallSignContract]}
          pspProvider={isFrom}
          registrationId={registrationId}
          setStep={setStep}
          setIsLoading={setIsLoadingAnyBank}
          setAllowedToGoToNextStep={setEnableSignContract}
        />
      )
    }
  }

  const renderButtons = () => {
    if (isLoadingAnyBank) {
      return (
        <s.PreviousNextButtonContainer>
          <div style={{ height: '42px' }} />
          <div style={{ height: '42px' }} />
        </s.PreviousNextButtonContainer>
      )
    }
    if (step.itauWelcomeStep) {
      return (
        <s.PreviousNextButtonContainer>
          <div style={{ height: '42px' }} />
          <div style={{ height: '42px' }} />
        </s.PreviousNextButtonContainer>
      )
    }

    if (step.itauDestinationAccount && !isLoadingAnyBank) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="back-button"
              onClick={() => {
                previousStep()
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="graytheme8" type="headline">
                Voltar
              </s.Text>
            </s.Button>
            <s.Button
              data-testid="verify-documents-button"
              width="304px"
              height="42px"
              disabled={!enableGoToDocumentsVerification || isLoadingAnyBank}
              onClick={() => {
                setCallRepresentativeValidation(true)
              }}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Continuar
              </s.Text>
              {isLoadingAnyBank ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : (
                <s.Icon name="arrowright" fill="white" />
              )}
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step.itauDocumentsVerification) {
      if (isLoadingAnyBank) {
        return (
          <s.PreviousNextButtonContainer>
            <div style={{ height: '42px' }} />
            <div style={{ height: '42px' }} />
          </s.PreviousNextButtonContainer>
        )
      }

      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="back-button"
              onClick={() => {
                previousStep()
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="graytheme8" type="headline">
                Voltar
              </s.Text>
            </s.Button>
            <s.Button
              data-testid="verify-documents-button"
              width="304px"
              height="42px"
              disabled={isLoadingAnyBank}
              onClick={() => setCallDocumentSubmission(true)}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Continuar
              </s.Text>
              {isLoadingAnyBank ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : (
                <s.Icon name="arrowright" fill="white" />
              )}
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }
    if (step.itauCompanyForm) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="company-validation-button"
              width="100%"
              height="42px"
              onClick={() => setCallCompanyValidation(true)}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Continuar
              </s.Text>
              <s.Icon name="arrowright" fill="white" />
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step.itauLegalRepresentativeForm && !isLoadingAnyBank) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="back-button"
              onClick={() => {
                previousStep()
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="graytheme8" type="headline">
                Voltar
              </s.Text>
            </s.Button>
            <s.Button
              data-testid="legalRepresentative-validation-button"
              width="304px"
              height="42px"
              onClick={() => setCallRepresentativeValidation(true)}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Continuar
              </s.Text>
              <s.Icon name="arrowright" fill="white" />
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step.itauSuccess) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="finish-onboard-button"
              width="100%"
              height="42px"
              onClick={() => handleCloseWithTracing()}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Finalizar
              </s.Text>
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step.itauTermsAndConditions) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              onClick={() => setCallSignContract(true)}
              disabled={!enableSignContract}
              data-testid="button-next-legal-representative-present"
              color="primary"
              width="100%"
            >
              <s.Text margin="0 8px 0 8px" fontWeight={600} color="white">
                Continuar
              </s.Text>
              <s.Icon name="arrowright" fill="white" />
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step.itauIdentityValidation) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              onClick={() => nextStep()}
              data-testid="button-next-legal-representative-present"
              color="primary"
              width="100%"
            >
              <s.Text margin="0 8px 0 8px" fontWeight={600} color="white">
                Iniciar validação de identidade
              </s.Text>
              <s.Icon name="arrowright" fill="white" />
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step.itauEndOnboard || step.error || step.errorOnProofOfLife) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="finish-onboard-button"
              width="100%"
              height="42px"
              color="whitedetailed"
              onClick={() => handleCloseWithTracing()}
            >
              <s.Text margin="0 8px 0 0" color="graytheme6" type="headline">
                Fechar
              </s.Text>
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step.itauIdWallSdkRender) {
      return (
        <s.PreviousNextButtonContainer>
          <div style={{ height: '42px' }} />
          <div style={{ height: '42px' }} />
        </s.PreviousNextButtonContainer>
      )
    }
  }

  return (
    <>
      <PixSimplesModalContainer
        step={step}
        isFrom={isFrom}
        isOpen={isOpen}
        handleClose={handleCloseWithTracing}
      >
        {isFrom === 'itau_anybank_v2' && !step.itauWelcomeStep ? (
          <>{renderButtons()}</>
        ) : (
          <s.CloseButton
            data-gtm-subname={`modal anybank`}
            data-gtm-name="botao fechar"
            data-gtm-type="click"
            data-gtm-clicktype="button"
            onClick={handleCloseWithTracing}
            name="closesquare"
            fill="white"
            width={28}
            height={28}
          />
        )}
        {renderModal()}
      </PixSimplesModalContainer>
    </>
  )
}

export default WalletSettingsFormPixSimples
