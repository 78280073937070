import React from 'react'

import {
  applyCpfCnpjMask,
  applyPhoneMask,
  applyCEPMask,
  applyDateMask,
  applyNumberOnlyMask,
} from 'helpers/masks'

import * as s from '../../styles'
import { baseURLRegistrationPixAnyBank, post } from 'services/api'
import { removeAccents, safeParseJSON } from 'helpers'
import { isNotEmpty, isValidBirthDate, isValidEmail } from 'helpers/validators'
import { showToast } from 'components/Toast'

import { useGetGeoAddress } from 'hooks/geo'

const AnyBankItauLegalRepresentantativeForm: React.FC<{
  legalRepresentativeDocumentNumber?: string
  nextStep: () => void
  previousStep: () => void
  registrationId: string
  callRepresentantiveValidationState?: [
    any,
    React.Dispatch<React.SetStateAction<any>>
  ]
  isFrom: string
  pixFormState: [any, React.Dispatch<React.SetStateAction<any>>]
  isLoadingState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}> = ({
  legalRepresentativeDocumentNumber,
  nextStep,
  registrationId,
  callRepresentantiveValidationState,
  pixFormState,
  isLoadingState,
}) => {
  const [form, setForm] = pixFormState
  const [, setIsLoading] = isLoadingState
  const {
    address,
    isLoading: isLoadingAddress,
    loadGeoAddress,
  } = useGetGeoAddress()

  const [legalRepresentantative, setLegalRepresentantive] = React.useState({
    name: '',
    document_number: legalRepresentativeDocumentNumber
      ? legalRepresentativeDocumentNumber
      : '',
    mother_name: '',
    birth_date: '',
    phone: '',
    email: '',
    street: '',
    number: '',
    complementary: '',
    neighborhood: '',
    city: '',
    state: '',
    zip_code: '',
  })

  const [legalRepresentantativeFormErrors, setLegalRepresentantiveFormErrors] =
    React.useState({
      name: '',
      document_number: '',
      mother_name: '',
      birth_date: '',
      phone: '',
      street: '',
      number: '',
      email: '',
      neighborhood: '',
      city: '',
      state: '',
      zip_code: '',
    })
  const [callRepresentantValidation, setCallRepresentantValidation] =
    callRepresentantiveValidationState

  React.useEffect(() => {
    if (legalRepresentativeDocumentNumber) {
      setLegalRepresentantiveFormErrors({
        ...legalRepresentantativeFormErrors,
        document_number: '',
      })
    }
    if (form?.legal_representative?.name) {
      setLegalRepresentantive({
        name: form.legal_representative?.name,
        document_number: form.legal_representative?.document_number,
        mother_name: form.legal_representative?.mother_name,
        birth_date: form.legal_representative?.birth_date
          .replaceAll('-', '/')
          .split('/')
          .reverse()
          .join('/'),
        phone: `(${
          form.legal_representative?.phone_ddd
        }) ${form.legal_representative?.phone_number.substring(
          0,
          5
        )}-${form.legal_representative?.phone_number.substring(5)}`,
        email: form.legal_representative?.email,
        zip_code: form.legal_representative?.address.zip_code,
        street: form.legal_representative?.address.street,
        number: form.legal_representative?.address.number,
        complementary: form.legal_representative?.address.complementary,
        neighborhood: form.legal_representative?.address.neighborhood,
        city: form.legal_representative?.address.city,
        state: form.legal_representative?.address.state,
      })
    }
  }, [])

  React.useEffect(() => {
    if (callRepresentantValidation) {
      representantValidation()
      setCallRepresentantValidation(false)
    }
  }, [callRepresentantValidation])

  const representantValidation = async () => {
    const err = { ...legalRepresentantativeFormErrors }
    const { complementary, ...requiredLegalRepresentativeFields } =
      legalRepresentantative
    for (const [key, value] of Object.entries(
      requiredLegalRepresentativeFields
    )) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    if (
      requiredLegalRepresentativeFields.phone.replace(/[^0-9]/g, '').length < 10
    ) {
      err.phone = 'Número de telefone inválido'
    }
    if (!isValidBirthDate(requiredLegalRepresentativeFields.birth_date)) {
      err.birth_date = 'Data inválida'
    }
    if (!isValidEmail(requiredLegalRepresentativeFields.email)) {
      err.email = 'E-mail inválido'
    }
    setLegalRepresentantiveFormErrors(err)

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      const phoneNumberOnly = legalRepresentantative.phone.replace(/\D/g, '')
      const legalRepresentativeInfo = {
        legal_representative: {
          document_number: legalRepresentantative.document_number.replace(
            /[./-]+/g,
            ''
          ),
          name: legalRepresentantative.name,
          mother_name: legalRepresentantative.mother_name,
          birth_date: legalRepresentantative.birth_date
            .replaceAll('/', '-')
            .split('-')
            .reverse()
            .join('-'),
          address: {
            street: legalRepresentantative.street,
            number: legalRepresentantative.number,
            complementary: legalRepresentantative.complementary
              ? legalRepresentantative.complementary
              : '',
            neighborhood: legalRepresentantative.neighborhood,
            city: legalRepresentantative.city,
            state: legalRepresentantative.state,
            zip_code: legalRepresentantative.zip_code,
          },
          phone_ddd: phoneNumberOnly.substring(0, 2),
          phone_number: phoneNumberOnly.substring(2, phoneNumberOnly.length),
          email: legalRepresentantative.email.toLowerCase(),
        },
      }

      setForm({
        ...form,
        ...legalRepresentativeInfo,
      })

      setIsLoading(true)
      await post(`${baseURLRegistrationPixAnyBank}`, {
        psp_provider: 'itau_anybank_v2',
        registration_id: registrationId,
        company: {
          ...form.company,
        },
        ...legalRepresentativeInfo,
      })
        .then((response) => {
          setIsLoading(false)
          nextStep()
        })
        .catch((e) => {
          if (window['env']['name'] !== 'production') {
            setIsLoading(false)
            nextStep()
          }
          setIsLoading(false)
          if (e.request.status === 503 || e.request.status === 500) {
            showToast({
              type: 'error',
              message: e.request.response || 'Algo deu errado',
            })
          }
          showToast({
            type: 'error',
            message:
              safeParseJSON(e.request.response)?.detail || 'Algo deu errado',
          })
        })
    }
  }

  React.useEffect(() => {
    if (address.city) {
      setLegalRepresentantive({
        ...legalRepresentantative,
        zip_code: address?.postal_code,
        city: removeAccents(address?.city),
        state: address?.state,
        street: removeAccents(address?.street),
        neighborhood: removeAccents(address?.neighborhood),
        number: '',
        complementary: '',
      })

      setLegalRepresentantiveFormErrors({
        ...legalRepresentantativeFormErrors,
        zip_code: '',
        city: '',
        state: '',
        street: '',
        neighborhood: '',
      })
    }
  }, [address])

  return (
    <s.Sections>
      <s.Section>
        <s.AnyBankItauRepresentantFormWrapper>
          <s.AnyBankSubHeaderText>
            <s.Icon name="customer" fill="graytheme6" />
            <s.Text bold type="headline" color="graytheme6">
              Dados do representante legal
            </s.Text>
          </s.AnyBankSubHeaderText>
          <s.Text
            fontWeight={400}
            type="paragraph"
            color="graytheme6"
            margin="0 0 0 8px"
          >
            Esta pessoa deve ter poderes para realizar a abertura da conta
            bancária em nome da empresa.
          </s.Text>
          <s.Text
            fontWeight={400}
            type="paragraph"
            color="graytheme6"
            margin="16px 0 42px 8px"
          >
            Posteriormente será necessário validar sua identidade por
            reconhecimento facial e análise de documento.
          </s.Text>
          <s.AnyBankItauInputWrapper>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.document_number
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                CPF
              </s.Text>
              <s.InputText
                testId="CPF-test"
                width={260}
                maxLength={14}
                value={legalRepresentantative.document_number}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    document_number: applyCpfCnpjMask(e.target.value),
                  })
                  if (legalRepresentantativeFormErrors.document_number) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      document_number: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.document_number
                    ? {
                        message:
                          legalRepresentantativeFormErrors.document_number,
                      }
                    : false
                }
                suffix={
                  legalRepresentantativeFormErrors.document_number ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.name
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Nome completo
              </s.Text>
              <s.InputText
                testId="name-test"
                width={260}
                value={legalRepresentantative.name}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    name: e.target.value,
                  })
                  if (legalRepresentantativeFormErrors.name) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      name: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.name
                    ? {
                        message: legalRepresentantativeFormErrors.name,
                      }
                    : false
                }
                suffix={
                  legalRepresentantativeFormErrors.name ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.mother_name
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Nome da mãe
              </s.Text>
              <s.InputText
                testId="mother-name-test"
                width={260}
                value={legalRepresentantative.mother_name}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    mother_name: e.target.value,
                  })
                  if (legalRepresentantativeFormErrors.mother_name) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      mother_name: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.mother_name
                    ? { message: legalRepresentantativeFormErrors.mother_name }
                    : false
                }
                suffix={
                  legalRepresentantativeFormErrors.mother_name ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.birth_date
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Data de nascimento
              </s.Text>
              <s.InputText
                testId="birth-date-test"
                width={260}
                maxLength={10}
                value={legalRepresentantative.birth_date}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    birth_date: applyDateMask(e.target.value),
                  })
                  if (legalRepresentantativeFormErrors.birth_date) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      birth_date: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.birth_date
                    ? {
                        message: legalRepresentantativeFormErrors.birth_date,
                      }
                    : false
                }
                suffix={
                  legalRepresentantativeFormErrors.birth_date ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.mother_name
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Telefone
              </s.Text>
              <s.InputText
                testId="phone-test"
                width={260}
                value={legalRepresentantative.phone}
                placeholder="(99) 99999-9999"
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    phone: applyPhoneMask(e.target.value),
                  })
                  if (legalRepresentantativeFormErrors.phone) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      phone: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.phone
                    ? { message: legalRepresentantativeFormErrors.phone }
                    : false
                }
                suffix={
                  legalRepresentantativeFormErrors.phone ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.phone
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                E-mail
              </s.Text>
              <s.InputText
                testId="email-test"
                width={260}
                value={legalRepresentantative.email}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    email: e.target.value,
                  })
                  if (legalRepresentantativeFormErrors.email) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      email: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.email
                    ? { message: legalRepresentantativeFormErrors.email }
                    : false
                }
                suffix={
                  legalRepresentantativeFormErrors.email ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.zip_code
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                CEP
              </s.Text>
              <s.InputText
                maxLength={9}
                testId="CEP-test"
                width={260}
                value={applyCEPMask(legalRepresentantative.zip_code)}
                onBlur={() => {
                  if (
                    legalRepresentantative?.zip_code?.replace(/-/g, '')
                      .length === 8 &&
                    legalRepresentantative?.zip_code?.replace(/-/g, '') !==
                      address?.postal_code
                  ) {
                    loadGeoAddress(
                      legalRepresentantative?.zip_code?.replace(/-/g, '')
                    )
                  }
                }}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    zip_code: e.target.value,
                  })
                  if (legalRepresentantativeFormErrors.zip_code) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      zip_code: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.zip_code
                    ? { message: legalRepresentantativeFormErrors.zip_code }
                    : false
                }
                suffix={
                  isLoadingAddress ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : legalRepresentantativeFormErrors.zip_code ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.street
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Endereço
              </s.Text>
              <s.InputText
                testId="street-test"
                disabled={address.street !== ''}
                width={260}
                value={legalRepresentantative.street}
                maxLength={115}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    street: e.target.value,
                  })
                  if (legalRepresentantativeFormErrors.street) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      street: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.street
                    ? { message: legalRepresentantativeFormErrors.street }
                    : false
                }
                suffix={
                  isLoadingAddress ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : legalRepresentantativeFormErrors.street ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>

            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.number
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Número
              </s.Text>
              <s.InputText
                testId="number-test"
                width={260}
                maxLength={9}
                value={legalRepresentantative.number}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    number: applyNumberOnlyMask(e.target.value),
                  })
                  if (legalRepresentantativeFormErrors.number) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      number: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.number
                    ? { message: legalRepresentantativeFormErrors.number }
                    : false
                }
                suffix={
                  legalRepresentantativeFormErrors.number ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                Complemento
              </s.Text>
              <s.InputText
                testId="complementary-test"
                width={260}
                maxLength={11}
                value={legalRepresentantative.complementary}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    complementary: e.target.value,
                  })
                }}
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.neighborhood
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Bairro
              </s.Text>
              <s.InputText
                testId="neighborhood-test"
                disabled={address.neighborhood !== ''}
                width={260}
                value={legalRepresentantative.neighborhood}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    neighborhood: e.target.value,
                  })
                  if (legalRepresentantativeFormErrors.neighborhood) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      neighborhood: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.neighborhood
                    ? { message: legalRepresentantativeFormErrors.neighborhood }
                    : false
                }
                suffix={
                  isLoadingAddress ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : legalRepresentantativeFormErrors.neighborhood ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.city
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Cidade
              </s.Text>
              <s.InputText
                testId="city-test"
                width={260}
                disabled
                value={legalRepresentantative.city}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    city: e.target.value,
                  })
                  if (legalRepresentantativeFormErrors.city) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      city: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.city
                    ? { message: legalRepresentantativeFormErrors.city }
                    : false
                }
                suffix={
                  legalRepresentantativeFormErrors.city ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  legalRepresentantativeFormErrors.state
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Estado
              </s.Text>
              <s.InputText
                testId="state-test"
                disabled
                width={260}
                value={legalRepresentantative.state}
                onChange={(e) => {
                  setLegalRepresentantive({
                    ...legalRepresentantative,
                    state: e.target.value,
                  })
                  if (legalRepresentantativeFormErrors.state) {
                    setLegalRepresentantiveFormErrors({
                      ...legalRepresentantativeFormErrors,
                      state: '',
                    })
                  }
                }}
                error={
                  legalRepresentantativeFormErrors.state
                    ? { message: legalRepresentantativeFormErrors.state }
                    : false
                }
                suffix={
                  legalRepresentantativeFormErrors.state ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
          </s.AnyBankItauInputWrapper>
        </s.AnyBankItauRepresentantFormWrapper>
      </s.Section>
    </s.Sections>
  )
}

export default AnyBankItauLegalRepresentantativeForm
